@import 'abstracts';

@media screen and (max-width: 768px) {
  cap-info-table p-table .p-datatable-wrapper table[role='grid'] .p-datatable-tbody tr.cap-info-table__row td {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  cap-info-table .cap-info-table.p-datatable .p-datatable-tbody td .cap-info-table__actions-column--flexed {
    border-bottom: none;
  }
}

.p-element.th_icon-column {

  /* stylelint-disable-line selector-class-pattern */
  width: 2.188rem;
}

.description {
  max-width: 18.75rem !important; /* stylelint-disable-line declaration-no-important */
}

body .cap-info-table.p-datatable .p-paginator {
  overflow: unset;

  .p-paginator-next {
    margin-left: 0;
  }
}

thead {
  th {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.table-filters.cap-table-filter .filter-icon {
  height: $cap-input-height !important; /* stylelint-disable-line declaration-no-important */
}
