body div.p-inputswitch.p-inputswitch-checked {
  &.p-inputswitch-focus .p-inputswitch-slider,
  &:not(.p-state-disabled):hover .p-inputswitch-slider,
  .p-inputswitch-slider {
    background-color: $cap-success;
  }
}

body cap-input-switch {
  margin: 0;

  .form-element {
    display: flex;
    align-items: center;

    label {
      display: block;
      margin-left: 10px;
      margin-bottom: 0;
    }
  }
}
