@import './styles/main.scss';

:root {
  --text-color-4: #fff;
}

html,
body {
  min-height: 100%;
}
