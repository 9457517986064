@import 'abstracts';

cap-calendar {
  span.cap-calendar.p-calendar {
    input.p-inputtext {
      font-family: $font-regular;
      color: $tundora;
      font-size: 0.875rem;
      font-weight: normal;
      padding-left: 0.313rem;

      &::-webkit-input-placeholder { /* stylelint-disable-line */
        color: $alto;
        font-size: 0.875rem;
        font-weight: normal;
      }
    }
  }

  .p-calendar-timeonly {
    width: 100%;

    input {
      width: 100%;
      height: 2.375rem;
      border: 0.063rem solid $green;

      &:hover,
      &:focus {
        outline: 0;
      }
    }
  }

  .p-datepicker-timeonly {
    display: none;
  }

  .p-calendar-w-btn .p-datepicker-trigger.p-button {
    background-color: white !important; /* stylelint-disable-line declaration-no-important */
    color: var(--cap-secondary-color) !important; /* stylelint-disable-line declaration-no-important */

    .p-button-icon.pi-calendar:before {
      font-size: 0.938rem;
      @include setFontAwesomeProIcon('\f073', 600);
    }
  }

  .p-calendar.transparent {
    .p-inputtext {
      border: none;
      background: none;
      opacity: 1;
    }

    .p-datepicker-trigger {
      display: none;
    }
  }

  .p-calendar .p-datepicker {
    padding: 0;
    border-radius: $border-radius-field;
    border-color: $alto;

    .p-datepicker-header {
      padding: 0.375rem 1.563rem;

      .p-datepicker-title {
        font-size: 1rem;
      }

      .p-datepicker-prev,
      .p-datepicker-next {
        color: $mine-shaft;

        .pi-chevron-left:before {
          font-size: 1.375rem;

          @include setFontAwesomeProIcon('\f104', 600);
        }

        .pi-chevron-right:before {
          font-size: 1.375rem;

          @include setFontAwesomeProIcon('\f105', 600);
        }
      }
    }

    .p-datepicker-calendar-container {
      padding: $small-gutter-width 1.563rem;

      table {
        margin: 0;
      }
    }

    table td span {
      padding: 0;
      width: 1.25rem;
      font-size: 0.813rem;
      height: 1.25rem;
      margin: 0.313rem;
      border-radius: 50%;

      @include flexCentering();
    }

    table td.p-datepicker-other-month > span.p-disabled {
      background: none;
    }
  }
}

body .p-disabled, body .p-component:disabled {
  background: none;
}
