/* Grid */
$grid-gutter-width: 1.875rem;
$middle-gutter-width: $grid-gutter-width / 2; /* stylelint-disable-line */
$small-gutter-width: $grid-gutter-width / 3; /* stylelint-disable-line */
$tiny-gutter-width: $grid-gutter-width / 4; /* stylelint-disable-line */

/* Colors */
$gray: #808080;
$light-gray: #d8d8d8;
$mid-gray: #5f6062;
$midnight-black: #00000040;
$gallery: #f0f0f0;
$mercury: #e3e3e3;
$wild-sand: #f6f6f6;
$barberry: #dce218;
$chateau-green: #35c44d;
$international-orange: #ff5400;
$school-bus-yellow: #ffdb00;
$lucky-point: #152b67;
$tundora: #4c4c4c;
$endeavour: #0057a0;
$roti: #c1ad52;
$silver-chalice: #a9a9a9;
$lochmara: #0070bf;
$keppel: #3ab5a7;
$alizarin-crimson: #e02020;
$atlantis: #82bb25;
$alabaster: #f9f9f9;
$scorpion: #565656;
$fuzzy-wuzzy-brown: #c15252;
$christine: #e3600f;
$porsche: #ec9b5f;

/** General **/
$primary: #f57414;
$primary-blue: #0c295b;
$font-color: #4c4c4c;

/** Secondary **/
$green: #4cd964;
$wrapper: #fafafa;
$alto: #ddd;
$mine-shaft: #333;
$green-vogue: #03295a;
$nobel: #b7b7b7;

/* Fonts */
$font-light: opensans sans-serif light;
$font-regular: opensans sans-serif regular;
$font-semi-bold: opensans sans-serif semibold;
$font-bold: opensans sans-serif bold;
$cap-primary-font: $font-regular;

/* Borders */
$border-radius-field: 0.188rem;

/** form controls */
$placeholder-color: $nobel;

/** Named variables **/
$error-color: $alizarin-crimson;

/* Overwrite Capturum/UI variabeles */
$cap-button-error-text-color: $error-color;

$icon-color-delete: $error-color;
$icon-color-edit: var(--cap-primary-color);
