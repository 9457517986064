@import 'abstracts';

cap-input {
  div.p-inputgroup {
    input.p-inputtext {
      font-size: 0.875rem;
      padding-left: 0.313rem;

      &:hover,
      &:enabled:hover:not(.ui-state-error),
      &:enabled:focus:not(.ui-state-error) {
        font-size: 0.875rem;
        padding-left: 0.313rem;
      }

      &:disabled {
        background: $alabaster;
      }

      &.transparent {
        background: transparent;
        border: none;
        cursor: text;
      }

      &.search {
        padding-left: 3.125rem;

        &:hover,
        &:enabled:hover:not(.ui-state-error),
        &:enabled:focus:not(.ui-state-error) {
          padding-left: 3.125rem;
        }
      }
    }
  }

  .p-inputgroup input.p-inputtext[disabled],
  .p-inputgroup input.p-inputtext.p-disabled,
  .p-inputgroup input.p-inputtext:disabled,
  .p-inputgroup input.p-inputtext:hover[disabled],
  .p-inputgroup input.p-inputtext:hover.p-disabled,
  .p-inputgroup input.p-inputtext:hover:disabled,
  .p-inputgroup .p-inputtext:enabled:hover:not(.p-state-error)[disabled],
  .p-inputgroup .p-inputtext:enabled:hover:not(.p-state-error).p-disabled,
  .p-inputgroup .p-inputtext:enabled:hover:not(.p-state-error):disabled,
  .p-inputgroup .p-inputtext:enabled:focus:not(.p-state-error)[disabled],
  .p-inputgroup .p-inputtext:enabled:focus:not(.p-state-error).p-disabled,
  .p-inputgroup .p-inputtext:enabled:focus:not(.p-state-error):disabled {
    padding-left: 0.313rem !important; /* stylelint-disable-line declaration-no-important */
    opacity: 1 !important; /* stylelint-disable-line declaration-no-important */
  }
}

body .form-element label {
  margin-left: 0;
  margin-bottom: 0.375rem;
  color: $tundora;
  font-size: 0.938rem;
  font-weight: bold;
}

body input::placeholder {
  color: $placeholder-color;
}
