@import 'abstracts';

h1 {
  font-size: 1.75rem;
  line-height: 1.75rem;
  margin-bottom: 1.313rem;
  font-weight: bold;
}

h2 {
  font-size: 1.313rem;
  line-height: 1.313rem;
  font-weight: bold;
  margin-bottom: 1.125rem;
}
