@import 'abstracts';

.loader {
  color: var(--cap-primary-color);
}

.logo-loader-wrapper {
  width: inherit;
  height: inherit;
  border-radius: inherit;
  object-fit: cover;
  position: absolute;
  flex-direction: row;

  @include flexCentering();

  .logo-loader {
    border: 1rem solid var(--cap-primary-color);
    border-top-color: $gray-600;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    animation: spin 2s linear infinite;
    position: absolute;
    color: transparent;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
