/* Base */
@import './base/reset';
@import './base/alignment';
@import './base/fonts';
@import './base/headings';

/* Components */
@import 'components/buttons';
@import 'components/info-table';
@import 'components/confirm-dialogs';
@import 'components/overlay-panel';
@import 'components/loader';
@import 'components/spinner';
@import 'components/switcher';
@import 'components/icons';
@import 'components/tile';
@import 'components/logo-uploader';
@import 'components/dialogs';
@import 'components/tile-documents';
@import 'components/scrum-container';
@import 'components/input';
@import 'components/dropdown';
@import 'components/calendars';

/* Theme */
@import './base/qube-theme';

/* Vendor */
@import './vendor/bootstrap';
@import './vendor/prime';
@import './vendor/capturum-ui';
@import './vendor/font-awesone';
