@import 'abstracts';

.tile {
  @include gridPadding();
  background-color: $white;
  border-radius: 0.5rem;
  border: 1px solid rgb(240, 240, 240);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: $grid-gutter-width;
  padding-top: 1rem;

  .title {
    height: 2.875rem;
    padding-bottom: $middle-gutter-width;
    border-bottom: 1px solid $cap-light-gray;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h2 {
      margin-bottom: 0;
    }

    &__options {
      display: flex;
      flex-direction: row;

      .edit-btn {
        border-color: transparent !important; /* stylelint-disable-line declaration-no-important */
        background: $wild-sand !important; /* stylelint-disable-line declaration-no-important */
      }

      .submit-btn {
        background-color: $atlantis !important; /* stylelint-disable-line declaration-no-important */
        color: $white !important; /* stylelint-disable-line declaration-no-important */
        margin-left: $small-gutter-width !important; /* stylelint-disable-line declaration-no-important */
        border-color: transparent !important; /* stylelint-disable-line declaration-no-important */
      }

      .delete-btn {
        background-color: $error-color !important; /* stylelint-disable-line declaration-no-important */
        color: $white !important; /* stylelint-disable-line declaration-no-important */
        margin-right: $small-gutter-width !important; /* stylelint-disable-line declaration-no-important */
        border-color: transparent !important; /* stylelint-disable-line declaration-no-important */
      }
    }
  }

  .body {
    padding-top: 0.875rem;
  }

  .no-results {
    font-size: 0.875rem;
    height: 3.125rem;
    display: flex;
    align-items: center;

    &__text {
      padding-left: $small-gutter-width;
    }
  }

  .label {
    font-weight: 600;
    font-size: 0.938rem;
  }

  .text {
    line-height: 2.2rem;
  }

  .default-line-height {
    line-height: 1.25rem;
  }

  .text-field {
    font-size: 0.938rem;
    line-height: 1.25rem;
  }

  .offset-link {
    margin-left: 0.313rem;
  }

  .min-row-input-height {
    min-height: 3.625rem;
  }
}
