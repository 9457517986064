@import 'abstracts';

body .p-component-overlay {
  background-color: $black (0.5) !important; /* stylelint-disable-line declaration-no-important */
  backdrop-filter: blur(0.625rem);
}

body div.p-dialog.p-confirm-dialog {
  div.p-dialog-content,
  div.p-dialog-footer,
  p-footer {
    @include flexCentering();
  }
}

body .p-dialog {
  padding: $grid-gutter-width !important; /* stylelint-disable-line declaration-no-important */
  background: $white;
  box-shadow: 0 0.313rem 0.813rem 0 $black (0.15) !important; /* stylelint-disable-line declaration-no-important */
  border-radius: 0.313rem;
  position: relative;
  width: 52.375rem;

  .p-dialog-header {
    border: none;
    background-color: $white;
    text-align: center;
    border-radius: 0.313rem;
    padding-bottom: $middle-gutter-width;
    z-index: 1;
  }

  .p-dialog-title {
    color: $tundora;
    font-size: 1.375rem;
    font-weight: bold;
    width: 100% !important; /* stylelint-disable-line declaration-no-important */
  }

  .p-dialog-title:first-letter {
    text-transform: capitalize;
  }

  .p-dialog-header-close-icon {
    color: $alto;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
  }
}

body .p-dialog-mask .p-dialog .p-dialog-content {
  border: none;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.dialog-footer {
  @include flexCentering();
  margin-top: $middle-gutter-width;
  font-size: 1rem;
}

.btn {
  margin: 0 0.313rem;
}
