@import 'abstracts';

.cap-overlay-panel.p-overlaypanel {
  border: none;
  margin-left: 0.813rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.188rem 0.375rem 0 rgba($black, 0.16);

  .p-overlaypanel-content {
    padding: 0;
  }

  &:before,
  &:after {
    border: 0;
  }

  &.header-title-bar {
    &:after,
    &:before {
      left: auto;
      margin-left: auto;
      border-width: 0.563rem;
      right: 0.438rem;
    }
  }
}
