@import 'variables';

@mixin gridPadding() {
  padding: $grid-gutter-width;
}

@mixin gridHorizontalPadding() {
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
}

@mixin gridVerticalPadding() {
  padding-top: $grid-gutter-width;
  padding-bottom: $grid-gutter-width;
}

@mixin flexCentering() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
}

@mixin straightTop() {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

@mixin straightBottom() {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

@mixin truncateAfterLine($line) {
  display: -webkit-box; /* stylelint-disable */
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin setFontAwesomeProIcon($code, $weight: 900) {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: $weight;
  content: $code;
}
