@import 'abstracts';

.tile-documents {
  .p-datatable-thead {
    display: none !important; /* stylelint-disable-line declaration-no-important */
  }

  .cap-info-table__column:first-child {
    width: 21.875rem !important; /* stylelint-disable-line declaration-no-important */
  }

  .cap-info-table__column:last-child {
    text-align: right !important; /* stylelint-disable-line declaration-no-important */
    width: 9.375rem !important; /* stylelint-disable-line declaration-no-important */
  }

  .cap-info-table {
    margin: -$small-gutter-width 0 !important; /* stylelint-disable-line declaration-no-important */
  }

  .far {
    margin-right: $small-gutter-width;
    font-size: 1.125rem;
    font-weight: 300;
  }

  .text {
    font-size: 0.875rem;
    font-weight: normal;
  }

  .file-name {
    color: $black;
  }

  .date {
    color: $mid-gray;
  }
}
