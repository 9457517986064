@import 'abstracts';

em-button button.em-button {
  &.primary {
    border-radius: $border-radius-field;
  }

  button.em-button {
    &:not(:disabled) {
      font-size: 0.875rem;

      &.large {
        font-size: 1.125rem;
      }
    }
  }
}

body .p-button.p-button-label-icon-left .p-button-label,
body .p-button.p-button-label-icon-right .p-button-label,
.p-button .p-button-label {
  padding-top: $small-gutter-width;
  padding-bottom: $small-gutter-width;
}

body cap-button {
  button.cap-button {
    .p-button-icon-left {
      margin-right: $small-gutter-width;
      padding: 0;
    }

    &.cap-button--loading {
      .p-button-icon {
        padding: 0;
      }

      .p-button-label {
        margin-left: 1em;
      }
    }

    &.tertiary:not(:disabled) {
      padding: 0 1.5rem;
      border-radius: 1rem;
      border-color: var(--cap-primary-color);
      font-size: 0.875rem;
      font-weight: 600;
      background: $white;
      color: var(--cap-primary-color);
      border-style: solid;
    }

    &.custom-btn:not(:disabled) {
      background: $white;
      border-radius: $border-radius-field;
      border: 0.063rem solid $primary-blue;
      box-shadow: 0 0.125rem 0.25rem 0 rgba(var(--cap-secondary-color), 0.25);
      color: $primary-blue;
      font-size: 0.938rem;
      font-weight: bold;
    }

    &.primary-btn:not(:disabled) {
      transition: all 0.5s;
      background: $white;
      border-radius: $border-radius-field;
      border: 0.063rem solid var(--cap-primary-color);
      box-shadow: 0 0.125rem 0.25rem 0 rgba(var(--cap-primary-color), 0.25);
      color: var(--cap-primary-color);
      font-size: 0.938rem;
      font-weight: bold;
    }

    &.primary:not(:disabled) {
      padding: 0 1rem;
    }

    &.primary:not(:disabled):hover,
    &.primary:not(:enabled):hover {
      filter: brightness(0.9) contrast(1.1);
      text-decoration: none;
    }

    &.secondary:not(:disabled) {
      padding: 0 1rem;
    }

    &.secondary:not(:disabled):hover,
    &.secondary:not(:enabled):hover {
      background-color: var(--cap-primary-color);
      color: $wrapper;
      text-decoration: none;
    }
  }
}
