@import 'abstracts';

.delete-icon {
  &:before {
    @include setFontAwesomeProIcon('\f2ed', 600);
  }
  cursor: pointer;
  color: $icon-color-delete;
}

.edit-icon {
  &:before {
    @include setFontAwesomeProIcon('\f303', 600);
  }
  cursor: pointer;
  color: $icon-color-edit;
}
