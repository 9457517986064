@import 'abstracts';

// Vars
$font-path: '../../assets/fonts/OpenSans';
$font-name: 'OpenSans';

@font-face {
  font-family: $font-regular;
  src: url('#{$font-path}/#{$font-name}-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $font-regular;
  src: url('#{$font-path}/#{$font-name}-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: $font-regular;
  src: url('#{$font-path}/#{$font-name}-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

.error-text {
  font-size: 0.625rem;
  margin: 0;
  text-align: right;
}

.back-btn {
  padding-left: 1.625rem;
}
