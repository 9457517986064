@import 'abstracts';

:root {
  --cap-primary-color: $primary;
  --primary-font: $font-regular;
}

* {
  outline: none;
}

body {
  color: $font-color;
}

/* Anchors */
body a,
body a:hover {
  color: $primary-blue;
  font-size: 0.813rem;
  text-decoration: underline;
}
