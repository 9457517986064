@import 'abstracts';
@import 'styles';
@import 'qube.theme';

@mixin searchInputTable($border-radius) {
  background: $white !important; /* stylelint-disable-line declaration-no-important */
  border-radius: #{$border-radius} !important; /* stylelint-disable-line declaration-no-important */
  border: 0.063rem solid $nobel !important; /* stylelint-disable-line declaration-no-important */
  padding-left: 2.188rem !important; /* stylelint-disable-line declaration-no-important */

  &::placeholder {
    color: $nobel !important; /* stylelint-disable-line declaration-no-important */
  }
}

cap-info-table p-table {
  .p-datatable-wrapper table {
    font-size: 0.875rem;

    .p-datatable-thead {
      tr th.cap-info-table__column-header {
        color: $nobel;
        font-size: 0.875rem;
        font-weight: 600;
        padding-bottom: 0.75rem;
        border-color: $cap-light-gray;
        padding-left: 1rem;
        padding-right: 1rem;

        &.icon-column {
          width: 3rem;
        }

        &.date-column {
          width: 8rem;
        }

        &:not(.p-highlight):hover {
          text-decoration: none;
        }
      }
    }

    .p-datatable-tbody {
      tr.cap-info-table__row {
        td {
          border: 1px solid $cap-light-gray;
          border-left: none;
          border-right: none;
          padding: 0 0.625rem;
        }

        td:first-child {
          border-left: 1px solid $cap-light-gray;
          border-bottom-left-radius: $border-radius-field;
          border-top-left-radius: $border-radius-field;
        }

        td:last-child {
          border-right: 1px solid $cap-light-gray;
          border-bottom-right-radius: $border-radius-field;
          border-top-right-radius: $border-radius-field;
        }
      }
    }
  }
}

body p-paginator {
  display: flex;

  .p-paginator-bottom {
    display: flex;
  }

  .p-paginator-left-content {
    span:first-child {
      margin-left: 0.125rem !important; /* stylelint-disable-line declaration-no-important */
    }

    cap-dropdown {
      height: 2rem !important; /* stylelint-disable-line declaration-no-important */

      .p-dropdown.num-rows-dropdown {
        height: 2rem !important; /* stylelint-disable-line declaration-no-important */

        .p-dropdown-label {
          font-size: 0.875rem;
          height: 1.875rem !important; /* stylelint-disable-line declaration-no-important */
        }
      }
    }
  }
}

body .p-paginator .p-paginator-pages .p-paginator-page,
body .p-paginator .p-paginator-first,
body .p-paginator .p-paginator-prev,
body .p-paginator .p-paginator-next,
body .p-paginator .p-paginator-last {
  height: 2.125rem;
}

cap-input.cap-info-table__search-input .p-inputgroup input.p-inputtext {
  @include searchInputTable(0.188rem);
}

cap-input.cap-user-table__search-input .p-inputgroup input.p-inputtext {
  @include searchInputTable(1.875rem);
}

cap-dropdown.cap-dropdown__search .p-dropdown .p-inputtext {
  background: $white !important; /* stylelint-disable-line declaration-no-important */
  padding-left: 2.2rem !important; /* stylelint-disable-line declaration-no-important */
  color: $nobel !important; /* stylelint-disable-line declaration-no-important */
}

.filter-icon {
  top: 0;
  left: 2.025rem;

  i {
    color: $nobel;
  }
}

cap-info-table.hide-table-header p-table .p-datatable-wrapper table .p-datatable-thead tr th.cap-info-table__column-header {
  padding: 0;
  height: 0;
  line-height: 0;
  font-size: 0;
  opacity: 0;
  pointer-events: none;
}

.unopened-dot {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: -0.375rem;
    right: -0.188rem;
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 50%;
    background: var(--cap-primary-color);
  }
}

cap-row-action {
  .row-action {
    margin-right: -0.688rem;
  }

  .row-action__items {
    width: 14rem;

    li {
      padding: 0.625rem 1.25rem;

      &[data-test='delete-action'] {
        i,
        p {
          color: $icon-color-delete !important; /* stylelint-disable-line declaration-no-important */
        }
      }
    }
  }

  .row-action__icon {
    margin: 0;
    width: 15%;

    @include flexCentering();
    justify-content: space-between;

    i {
      color: $primary-blue;
      font-size: 1.125rem;
    }
  }

  .row-action__info .title {
    padding-bottom: 0;
    font-weight: normal;
    color: $tundora;
    font-size: 0.875rem;
    border-bottom: none;
  }
}
