@import 'abstracts';

body {
  .cap-rounded.cap-multi-select.p-multiselect,
  .cap-rounded.cap-dropdown.p-dropdown,
  .cap-rounded.cap-dropdown.p-dropdown:not(.p-disabled):hover,
  .cap-rounded.cap-dropdown.p-dropdown:not(.p-disabled).p-focus,
  .cap-rounded.cap-multi-select.p-multiselect:not(.p-disabled):hover {
    border: 1px solid $nobel;
    font-size: 0.875rem;
    font-weight: normal;
    color: $font-color !important; /* stylelint-disable-line declaration-no-important */
  }

  .cap-multi-select-panel.p-multiselect-panel .p-multiselect-filter-container .p-multiselect-filter-icon {
    left: 0 !important; /* stylelint-disable-line declaration-no-important */
    color: var(--cap-primary-color) !important; /* stylelint-disable-line declaration-no-important */
  }

  .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    margin-top: 0 !important; /* stylelint-disable-line declaration-no-important */
  }

  .cap-dropdown.p-dropdown .p-dropdown-label {
    height: 2.063rem !important; /* stylelint-disable-line declaration-no-important */
  }
}

.cap-rounded.cap-multi-select.p-multiselect .p-dropdown-trigger,
.cap-rounded.cap-multi-select.p-multiselect .p-multiselect-trigger,
.cap-rounded.cap-dropdown.p-dropdown .p-dropdown-trigger,
.cap-rounded.cap-dropdown.p-dropdown .p-multiselect-trigger,
.cap-rounded.cap-dropdown.p-dropdown:not(.p-disabled):hover .p-dropdown-trigger,
.cap-rounded.cap-dropdown.p-dropdown:not(.p-disabled):hover .p-multiselect-trigger,
.cap-rounded.cap-dropdown.p-dropdown:not(.p-disabled).p-focus .p-dropdown-trigger,
.cap-rounded.cap-dropdown.p-dropdown:not(.p-disabled).p-focus .p-multiselect-trigger,
.cap-rounded.cap-multi-select.p-multiselect:not(.p-disabled):hover .p-dropdown-trigger {
  margin-right: 10px !important; /* stylelint-disable-line declaration-no-important */
}

cap-dropdown {
  p-dropdown {
    .cap-dropdown {
      border-radius: $border-radius-field;
      font-size: 0.875rem;

      &:hover,
      &:enabled:hover:not(.ui-state-error),
      &:enabled:focus:not(.ui-state-error) {
        font-size: 0.875rem;
      }

      &.transparent {
        background: transparent;
        border: none;
        box-shadow: none;
        opacity: 1;

        .p-dropdown-trigger {
          display: none;
        }
      }

      &.search {
        padding-left: 3.125rem;

        &:hover,
        &:enabled:hover:not(.ui-state-error),
        &:enabled:focus:not(.ui-state-error) {
          padding-left: 3.125rem;
        }
      }
    }
  }
}

.p-dropdown-trigger {
  border-radius: 0 $border-radius-field $border-radius-field 0;
}

.p-multiselect .p-multiselect-panel {
  width: 18.75rem;
}

.p-dropdown-empty-message {
  padding: 0.625rem 1.25rem;
}
