@import 'abstracts';

.scrum-container {
  .label {
    color: $mine-shaft;
    font-size: 1.063rem;
    font-weight: bold;
  }

  .summary-container {
    height: 9.438rem;
    background: white;
    border: 0.063rem solid $gallery;
    border-radius: 0.5rem;
    flex-direction: column;
    padding: 1rem;

    @include flexCentering();

    &__icon {
      color: $alto;
      font-size: 2.813rem;
      font-weight: 300;
      padding-bottom: 1.25rem;
    }

    &__label {
      color: $mine-shaft;
      font-size: 0.875rem;
      font-weight: 600;
      padding-bottom: $small-gutter-width;
      text-align: center;
    }

    &__info {
      color: var(--cap-primary-color);
      font-size: 1.5rem;
      font-weight: 600;
    }
  }
}
