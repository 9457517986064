@import 'abstracts';

$logo-size: 6.875rem;
$logo-radius: 50%;

.tile {
  .logo-wrapper {
    width: $logo-size;
    height: $logo-size;
    border-radius: $logo-radius;
    box-shadow: 0 0.125rem 0.25rem 0 $midnight-black;
    margin-bottom: $middle-gutter-width;

    .logo {
      width: inherit;
      height: inherit;
      border-radius: inherit;
      object-fit: cover;
      position: absolute;
    }

    cap-file-upload {
      border-radius: $logo-radius;

      div {
        width: $logo-size;
        height: $logo-size;
        border-radius: inherit;
        object-fit: cover;
        position: absolute;

        label {
          width: 100%;
          height: 100%;
          padding: 0;
          margin: 0;
          border-radius: inherit;
          box-shadow: none;
          background-color: transparent;
          flex-direction: row;
          opacity: 0;

          &:hover {
            background: var(--cap-primary-color);
            opacity: 0.7;
          }

          @include flexCentering();

          i,
          p {
            color: $white;
            z-index: 1;
            opacity: 1;
            display: inline-block;
          }

          i {
            font-size: 1.063rem;
            margin-right: $tiny-gutter-width;
            margin-top: 0.19rem;
          }

          p {
            font-weight: 600;
          }
        }
      }
    }
  }
}
